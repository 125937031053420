const containerStyle = {
  display: 'flex',
}

const itemStyle = {
  backgroundColor: '#eef1fa',
  borderRadius: '8px',
  /*
   * Best practice:
   * Set skeleton dimensions to the eventual rendered dimensions to reduce content shift
   */
  width: '84px',
  height: '86px',
  marginRight: '30px',
}

const DevicesListSkeleton = () => (
  <div style={containerStyle}>
    <span style={itemStyle} />
    <span style={itemStyle} />
    <span style={itemStyle} />
    <span style={itemStyle} />
    <span style={itemStyle} />
  </div>
)

export default DevicesListSkeleton
