import PropTypes from 'prop-types'

import { ERROR_THRESHOLD } from '../../util/config'

const containerStyle = {
  backgroundColor: '#eef1fa',
  marginRight: '30px',
  display: 'grid',
  justifyItems: 'center',
  gridRowGap: '10px',
  padding: '10px',
  borderRadius: '8px',
}

const titleStyle = {
  fontWeight: 'bold',
}

const valueStyle = {
  fontSize: '2rem',
}

const Device = ({ title, value }) => {
  // Randomly throw device errors:
  if (Math.random() > ERROR_THRESHOLD) throw new Error('Device Error!')

  return (
    <div style={containerStyle}>
      <span style={titleStyle}>{title}</span>
      <span style={valueStyle}>{value}</span>
    </div>
  )
}

Device.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
}

export default Device
