const LOCATIONS = ['Chicago, IL', 'Denver, CO', 'New York, NY']
export function generateMockProperties() {
  let items = []
  for (var i = 0; i < 3; i++) {
    items.push({
      id: i + 1,
      title: `Property #${i + 1}`,
      location: LOCATIONS[i],
    })
  }
  return items
}

export function generateMockDevices() {
  let devices = []
  const count = Math.floor(Math.random() * 3) + 3
  for (var i = 1; i < count; i++) {
    devices.push({
      id: i,
      title: `Device ${i}`,
      value: Math.floor(Math.random() * 100),
    })
  }
  return devices
}
