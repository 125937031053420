import Header from './components/ui/Header'
import SideBar from './components/ui/SideBar'
import Dashboard from './components/Dashboard'

const wrapperStyle = {
  width: '80%',
  display: 'grid',
  justifyItems: 'center',
  padding: '0 10%',
}

const containerStyle = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 3fr',
  gridColumnGap: '30px',
}

const App = () => (
  <div style={wrapperStyle}>
    <Header />
    <div style={containerStyle}>
      <SideBar />
      <Dashboard />
    </div>
  </div>
)

export default App
