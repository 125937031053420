const style = {
  backgroundColor: '#fdc1c8',
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
  width: '84px',
  height: '86px',
  marginRight: '30px',
  borderRadius: '8px',
}

const DeviceError = () => <div style={style}>❌</div>

export default DeviceError
