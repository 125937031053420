import { useState, useEffect } from 'react'

import PropertyList from './ui/PropertyList'
import PropertyContainer from './PropertyContainer'
import PropertySkeleton from './skeletons/PropertySkeleton'
import PropertyErrorBoundary from './boundaries/PropertyErrorBoundary'

import { generateMockProperties } from '../util/mocks'

const Dashboard = () => {
  const [properties, setProperties] = useState()

  useEffect(() => {
    setTimeout(() => {
      setProperties(generateMockProperties())
    }, 2000)
  }, [])

  return (
    <PropertyList>
      {properties &&
        properties.map(property => (
          <PropertyErrorBoundary key={property.id}>
            <PropertyContainer
              title={property.title}
              location={property.location}
            />
          </PropertyErrorBoundary>
        ))}
      {!properties && (
        <>
          <PropertySkeleton />
          <PropertySkeleton />
          <PropertySkeleton />
        </>
      )}
    </PropertyList>
  )
}

export default Dashboard
