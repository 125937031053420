import PropTypes from 'prop-types'

import { ERROR_THRESHOLD } from '../../util/config'
import DevicesList from '../ui/DevicesList'
import DevicesListSkeleton from '../skeletons/DevicesListSkeleton'

const style = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '5px 0 30px 30px',
}

const Property = ({ title, location, devices = null }) => {
  // Randomly throw property errors:
  if (Math.random() > ERROR_THRESHOLD) throw new Error('Property Error!')

  return (
    <div style={style}>
      <h3>{title}</h3>
      <p>{location}</p>
      {devices && <DevicesList devices={devices} />}
      {!devices && <DevicesListSkeleton />}
    </div>
  )
}

Property.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ),
}

export default Property
