const containerStyle = {
  backgroundColor: '#fdc1c8',
  display: 'grid',
  justifyItems: 'center',
  borderRadius: '8px',
  height: '114px',
  padding: '50px 0',
}

const emojiStyle = {}

const headerStyle = {
  fontWeight: 'bold',
  fontSize: '1.2rem',
}

const bodyStyle = {
  fontSize: '0.9rem',
}

const PropertyError = () => (
  <div style={containerStyle}>
    <span style={emojiStyle}>❌</span>
    <span style={headerStyle}>Oops</span>
    <span style={bodyStyle}>This property cannot be loaded.</span>
  </div>
)

export default PropertyError
