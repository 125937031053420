import DevicesListSkeleton from './DevicesListSkeleton'

const containerStyle = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  /*
   * Best practice:
   * Set skeleton dimensions to the eventual rendered dimensions to reduce content shift
   */
  height: '179px',
  padding: '5px 0 30px 30px',
}

const titleStyle = {
  backgroundColor: '#eef1fa',
  width: '300px',
  height: '22px',
  margin: '18px 0',
}

const locationStyle = {
  backgroundColor: '#eef1fa',
  width: '200px',
  height: '18px',
  margin: '16px 0',
}

const PropertySkeleton = () => (
  <div style={containerStyle}>
    <div style={titleStyle} />
    <div style={locationStyle} />
    <DevicesListSkeleton />
  </div>
)

export default PropertySkeleton
