const style = {
  width: '100%',
  padding: '20px 0',
}

const Header = () => (
  <div style={style}>
    <h1>Vivo Demo</h1>
  </div>
)

export default Header
