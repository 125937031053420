import PropTypes from 'prop-types'

import Device from './Device'
import DeviceErrorBoundary from '../boundaries/DeviceErrorBoundary'

const style = {
  display: 'flex',
}

const DevicesList = ({ devices }) => (
  <div style={style}>
    {devices.map(device => (
      <DeviceErrorBoundary key={device.id}>
        <Device title={device.title} value={device.value} />
      </DeviceErrorBoundary>
    ))}
  </div>
)

DevicesList.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default DevicesList
