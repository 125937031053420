function getErrorThreshold() {
  const urlParams = new URLSearchParams(window.location.search)
  const errorThreshold = urlParams.get('errorThreshold')

  // Add errorThreshold parameter if it doesn't exist:
  if (!errorThreshold) {
    window.location.search = 'errorThreshold=100'
    errorThreshold = 100
  }

  return Number(errorThreshold) / 100
}

export const ERROR_THRESHOLD = getErrorThreshold()

const config = {
  ERROR_THRESHOLD,
}

export default config
