const style = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '0 30px',
}

const SideBar = () => (
  <div style={style}>
    <h2>Sidebar</h2>
  </div>
)

export default SideBar
