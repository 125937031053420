import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Property from './ui/Property'
import { generateMockDevices } from '../util/mocks'

const PropertyContainer = ({ title, location }) => {
  const [devices, setDevices] = useState()

  useEffect(() => {
    setTimeout(() => {
      setDevices(generateMockDevices())
    }, 2000)
  }, [])

  return <Property title={title} location={location} devices={devices} />
}

PropertyContainer.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
}

export default PropertyContainer
